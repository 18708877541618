@tailwind base;
@tailwind components;
@tailwind utilities;
/*
@import "./constants/colors";
@import "./constants/mixins";

@import "./controls/controls";

body {
  margin: auto;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
